import {
  Box,
  BoxProps,
  Center,
  CloseButton,
  Flex,
  GridItem,
  HStack,
  IconButton,
  IconButtonProps,
  useBreakpointValue,
  useColorModeValue,
  useUpdateEffect,
} from "@chakra-ui/react";
import { AnimatePresence, motion, useElementScroll } from "framer-motion";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { forwardRef, ReactNode, Ref, useEffect, useRef, useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { RemoveScroll } from "react-remove-scroll";

import Logo from "./logo";
import { SidebarContent } from "./sidebar/sidebar";

import { routes } from "~/configs/sidebar-routes";
import useRouteChanged from "~/hooks/use-route-changed";

type NavLinkProps = {
  href: string;
  children: ReactNode;
};

function NavLink({ href, children }: NavLinkProps) {
  const router = useRouter();
  const bgActiveHoverColor = useColorModeValue("gray.100", "whiteAlpha.100");

  const isActive = router.asPath.startsWith(href);

  return (
    <GridItem as={NextLink} href={href}>
      <Center
        flex="1"
        minH="40px"
        as="button"
        rounded="md"
        transition="0.2s all"
        fontWeight={isActive ? "semibold" : "medium"}
        bg={isActive ? "blue.400" : undefined}
        borderWidth={isActive ? undefined : "1px"}
        color={isActive ? "white" : undefined}
        _hover={{
          bg: isActive ? "blue.500" : bgActiveHoverColor,
        }}
      >
        {children}
      </Center>
    </GridItem>
  );
}

interface MobileNavContentProps {
  isOpen?: boolean;
  onClose?: () => void;
}

export function MobileNavContent(props: MobileNavContentProps) {
  const { isOpen, onClose } = props;
  const closeBtnRef = useRef<HTMLButtonElement | null>(null);
  const { pathname, asPath } = useRouter();
  const bgColor = useColorModeValue("white", "gray.800");

  useRouteChanged(onClose ?? (() => {}));

  /**
   * Scenario: Menu is open on mobile, and user resizes to desktop/tablet viewport.
   * Result: We'll close the menu
   */
  const showOnBreakpoint = useBreakpointValue({ base: true, lg: false });

  useEffect(() => {
    if (showOnBreakpoint == false) {
      onClose?.();
    }
  }, [showOnBreakpoint, onClose]);

  useUpdateEffect(() => {
    if (isOpen) {
      requestAnimationFrame(() => {
        closeBtnRef.current?.focus();
      });
    }
  }, [isOpen]);

  const [shadow, setShadow] = useState<string>();

  return (
    <AnimatePresence>
      {isOpen && (
        <RemoveScroll forwardProps>
          <motion.div
            transition={{ duration: 0.08 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Flex
              direction="column"
              w="100%"
              bg={bgColor}
              h="100vh"
              overflow="auto"
              pos="absolute"
              top="0"
              left="0"
              zIndex={20}
              pb="8"
            >
              <Box>
                <Flex justify="space-between" px="6" pt="5" pb="4">
                  <Logo height="1.5rem" />
                  <HStack spacing="5">
                    <CloseButton ref={closeBtnRef} onClick={onClose} />
                  </HStack>
                </Flex>
              </Box>

              <ScrollView
                onScroll={(scrolled) => {
                  setShadow(scrolled ? "md" : undefined);
                }}
              >
                <SidebarContent routes={routes} />
              </ScrollView>
            </Flex>
          </motion.div>
        </RemoveScroll>
      )}
    </AnimatePresence>
  );
}

type ScrollViewProps = BoxProps & {
  onScroll: (scrolled: boolean) => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ScrollView = (props: ScrollViewProps) => {
  const { onScroll, ...rest } = props;
  const [y, setY] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const elRef = useRef<any>();
  const { scrollY } = useElementScroll(elRef);
  useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);

  useUpdateEffect(() => {
    onScroll?.(y > 5 ? true : false);
  }, [y]);

  return (
    <Box
      ref={elRef}
      flex="1"
      id="routes"
      overflow="auto"
      px="6"
      pb="6"
      {...rest}
    />
  );
};

export const MobileNavButton = forwardRef(
  (props: IconButtonProps, ref: Ref<HTMLButtonElement>) => {
    return (
      <IconButton
        ref={ref}
        display={{ base: "flex", md: "none" }}
        fontSize="20px"
        color={useColorModeValue("gray.800", "inherit")}
        variant="ghost"
        icon={<AiOutlineMenu />}
        {...props}
      />
    );
  }
);
