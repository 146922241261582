import { PropsOf, chakra, useColorModeValue, Flex } from "@chakra-ui/react";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { forwardRef, Ref, useEffect, useRef } from "react";

const StyledLink = forwardRef(function StyledLink(
  props: PropsOf<typeof chakra.a> & { isActive?: boolean },
  ref: Ref<any>
) {
  const { isActive, ...rest } = props;

  return (
    <chakra.a
      aria-current={isActive ? "page" : undefined}
      width="100%"
      px="3"
      py="1"
      rounded="md"
      ref={ref}
      fontSize="sm"
      color="fg"
      _hover={{ color: "accent" }}
      transition="all 0.2s"
      _activeLink={{
        bg: useColorModeValue("blue.50", "blue.900"),
        color: "accent-emphasis",
      }}
      {...rest}
    />
  );
});

export type SidebarLinkProps = PropsOf<typeof chakra.div> & {
  href: string;
  icon?: React.ReactElement;
};

export const SidebarLink = ({ href, children, ...rest }: SidebarLinkProps) => {
  const router = useRouter();
  const isActive = href === router.asPath;

  const link = useRef<HTMLAnchorElement>();

  useEffect(() => {
    if (isActive && router.query.scroll === "true") {
      link.current?.scrollIntoView({ block: "center" });
    }
  }, [isActive, router.query]);

  return (
    <Flex align="center" userSelect="none" lineHeight="tall" {...rest}>
      <NextLink href={href} passHref>
        <StyledLink isActive={isActive} ref={link}>
          {children}
        </StyledLink>
      </NextLink>
    </Flex>
  );
};
