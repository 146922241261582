import { RouteItem } from "~/utils/get-route-context";

export const routes: RouteItem[] = [
  {
    title: "Overview",
    heading: true,
    routes: [
      {
        title: "Introduction to Rollout",
        path: "/overview/introduction-to-rollout",
      },
      {
        title: "Getting Started",
        path: "/overview/getting-started",
      },
    ],
  },
  {
    title: "Quickstart Guides",
    heading: true,
    routes: [
      {
        title: "Embedded Zapier",
        path: "/guides/embedded-zapier",
      },
      { title: "Migrating from Zapier", path: "/guides/migrating-from-zapier" },
    ],
  },
  {
    title: "Documentation",
    heading: true,
    routes: [
      {
        title: "Fundamentals",
        path: "/documentation/fundamentals",
      },
      {
        title: "Rollout Overview",
        path: "/documentation/rollout-overview",
      },
      {
        title: "Triggers",
        path: "/documentation/triggers",
        routes: [
          {
            title: "Creating Triggers",
            path: "/documentation/triggers/creating-triggers",
          },
          {
            title: "Publishing, Updating & Deleting Triggers",
            path: "/documentation/triggers/publishing-updating-deleting-triggers",
          },
          {
            title: "Using REST Hooks",
            path: "/documentation/triggers/using-rest-hooks",
          },
          {
            title: "Using Polling Triggers",
            path: "/documentation/triggers/polling-triggers",
          },
        ],
      },
      {
        title: "Actions",
        path: "/documentation/actions",
        routes: [
          {
            title: "Creating Actions",
            path: "/documentation/actions/creating-actions",
          },
          {
            title: "Publishing, Updating & Deleting Actions",
            path: "/documentation/actions/publishing-updating-deleting-actions",
          },
        ],
      },
      {
        title: "Authenticating with Rollout",
        path: "/documentation/authenticating-with-rollout",
      },
      {
        title: "User Auth",
        path: "/documentation/user-auth",
        routes: [
          {
            title: "OAuth Implementation",
            path: "/documentation/user-auth/oauth",
          },
          {
            title: "API Key Implementation",
            path: "/documentation/user-auth/api-key",
          },
        ],
      },
      {
        title: "Automations",
        path: "/documentation/automations",
        routes: [
          {
            title: "Automation custom data",
            path: "/documentation/automations/automation-custom-data",
          },
          {
            title: "Recieving Automation Results",
            path: "/documentation/receiving-automation-results",
          },
        ],
      },
      {
        title: "Blueprints",
        path: "/documentation/blueprints",
      },
      {
        title: "Managed auth",
        path: "/documentation/managed-auth",
      },
      {
        title: "White-labeling",
        path: "/documentation/white-labeling",
        routes: [
          {
            title: "Google Apps",
            path: "/documentation/white-labeling/google-apps",
          },
        ],
      },
      {
        title: "UI Components",
        path: "/documentation/ui-components",
        routes: [
          {
            title: "Installation",
            path: "/documentation/ui-components/installation",
          },
          {
            title: "Limiting Allowed Apps",
            path: "/documentation/ui-components/limiting-allowed-apps",
          },
          {
            title: "Prefilling Automation Form data",
            path: "/documentation/ui-components/prefilling-automation-form-data",
          },
          {
            title: "Customizing the Automation Form",
            path: "/documentation/ui-components/customizing-automation-form",
          },
          {
            title: "Customizing Input Fields",
            path: "/documentation/ui-components/customizing-input-fields",
          },
        ],
      },
      {
        title: "Code Mode Runtime",
        path: "/documentation/code-mode-runtime",
      },
      {
        title: "SDK",
        path: "/documentation/sdk",
      },
    ],
  },
  {
    title: "UI Components",
    heading: true,
    routes: [
      {
        title: "AutomationCreator",
        path: "/components/automation-creator/usage",
      },
      {
        title: "AutomationEditor",
        path: "/components/automation-editor/usage",
      },
      {
        title: "AutomationsManager",
        path: "/components/automations-manager/usage",
      },
      {
        title: "BlueprintEnabler",
        path: "/components/blueprint-enabler/usage",
      },
      {
        title: "BlueprintsManager",
        path: "/components/blueprints-manager/usage",
      },
      {
        title: "MyAutomations",
        path: "/components/my-automations/usage",
      },
      {
        title: "RolloutConnectProvider",
        path: "/components/rollout-connect-provider/usage",
      },
      {
        title: "TaskInitiator",
        path: "/components/task-initiator/usage",
      },
    ],
  },
  {
    title: "API",
    heading: true,
    routes: [
      {
        title: "Automation Data Type Definition",
        path: "/apis/automation-data-type",
      },
      {
        title: "API Reference",
        path: "/apis/api-reference",
      },
      {
        title: "Task Data Type Definitions",
        path: "/apis/task-data-type",
      },
    ],
  },
  {
    title: "Security & Legal",
    heading: true,
    routes: [
      {
        title: "Security",
        path: "/security-and-legal/security",
      },
      {
        title: "GDPR",
        path: "/security-and-legal/gdpr",
      },
      {
        title: "Privacy Policy",
        path: "/security-and-legal/privacy-policy",
      },
      {
        title: "Terms of Service",
        path: "/security-and-legal/terms-of-service",
      },
      {
        title: "Data Processing Agreement",
        path: "/security-and-legal/data-processing-agreement",
      },
      {
        title: "Data Subprocessors",
        path: "/security-and-legal/data-subprocessors",
      },
      {
        title: "Google API Services Disclosure",
        path: "/security-and-legal/google-api-disclosure",
      },
      {
        title: "Contact Us",
        path: "/security-and-legal/contact-us",
      },
    ],
  },
  {
    title: "Third Party Apps",
    heading: true,
    path: "/third-party-apps",
    routes: [{ title: "Overview", path: "/third-party-apps/overview" }],
  },
  {
    title: "User Guides",
    heading: true,
    routes: [
      {
        title: "Overview",
        path: "/user-guides/overview",
      },
      {
        title: "Hubspot",
        path: "/user-guides/hubspot",
      },
      {
        title: "Notion Markdown Cheat Sheet",
        path: "/user-guides/notion-markdown-cheat-sheet",
      },
      {
        title: "Slack",
        path: "/user-guides/slack",
      },
    ],
  },
];
