import {
  Box,
  Flex,
  HStack,
  IconButton,
  chakra,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useUpdateEffect,
} from "@chakra-ui/react";
import NextLink from "next/link";
import { useRef } from "react";
import { FaMoon, FaSun } from "react-icons/fa";

import Logo, { LogoIcon } from "./logo";
import { MobileNavButton, MobileNavContent } from "./mobile-nav";
import Search from "./omni-search";

function HeaderContent() {
  const mobileNav = useDisclosure();

  const { toggleColorMode: toggleMode } = useColorMode();

  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const mobileNavBtnRef = useRef<HTMLButtonElement | null>(null);

  useUpdateEffect(() => {
    mobileNavBtnRef.current?.focus();
  }, [mobileNav.isOpen]);

  return (
    <>
      <Flex w="100%" h="100%" px="6" align="center" justify="space-between">
        <Flex align="center">
          <NextLink href="/" passHref>
            <chakra.a display="block" aria-label="Rollout, Back to homepage">
              <Logo display={{ base: "none", md: "block" }} height="1.5rem" />
              <Box minW="3rem" display={{ base: "block", md: "none" }}>
                <LogoIcon height="1.5rem" />
              </Box>
            </chakra.a>
          </NextLink>
        </Flex>

        <Flex
          justify="flex-end"
          w="100%"
          align="center"
          color="gray.400"
          maxW="1100px"
        >
          <Search />

          <HStack spacing="5">
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={`Switch to ${text} mode`}
              variant="ghost"
              color="current"
              onClick={toggleMode}
              icon={<SwitchIcon />}
            />
            <MobileNavButton
              ref={mobileNavBtnRef}
              aria-label="Open Menu"
              onClick={mobileNav.onOpen}
            />
          </HStack>
        </Flex>
      </Flex>
      <MobileNavContent isOpen={mobileNav.isOpen} onClose={mobileNav.onClose} />
    </>
  );
}

function Header() {
  return (
    <>
      <chakra.header
        borderBottomWidth="1px"
        transition="border-color 0.2s, background-color 0.2s"
        pos="fixed"
        top="0"
        zIndex="3"
        bg="white"
        _dark={{ bg: "gray.800" }}
        left="0"
        right="0"
        width="full"
      >
        <chakra.div background="#427cff" color="white">
          <chakra.div mx="auto" maxW="8xl" px="6" py="2">
            This documentation covers Rollout v1. For Rollout v2 docs and
            migration guide, see{" "}
            <chakra.a
              textDecoration="underline"
              href="https://docs.rollout.com"
            >
              docs.rollout.com
            </chakra.a>
            .
          </chakra.div>
        </chakra.div>

        <chakra.div height="4.5rem" mx="auto" maxW="8xl">
          <HeaderContent />
        </chakra.div>
      </chakra.header>
    </>
  );
}

export default Header;
