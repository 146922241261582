import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, chakra } from "@chakra-ui/react";
import { sortBy } from "lodash";
import { useRouter } from "next/router";
import { useRef } from "react";

import SidebarCategory from "./sidebar-category";
import { SidebarLink } from "./sidebar-link";

import { convertBackticksToInlineCode } from "~/utils/convert-backticks-to-inline-code";
import { RouteItem, Routes } from "~/utils/get-route-context";

export type SidebarContentProps = Routes & {
  contentRef?: any;
};

export function SidebarContent({ routes, contentRef }: SidebarContentProps) {
  const router = useRouter();

  return (
    <>
      {routes.map((lvl1, idx) => {
        const isLvl1Active = lvl1.routes!.some((lvl1Route) =>
          router.asPath.startsWith(lvl1Route.path!)
        );

        return (
          <chakra.div key={idx} marginBottom="6">
            {lvl1.heading && (
              <chakra.h4 fontSize="sm" fontWeight="medium" my="3">
                <chakra.a
                  href={lvl1.routes![0].path}
                  display="flex"
                  gap="2"
                  justifyContent="space-between"
                  alignItems="center"
                  _hover={{ color: "blue.500" }}
                >
                  {lvl1.title}{" "}
                  {isLvl1Active ? <ChevronDownIcon /> : <ChevronRightIcon />}
                </chakra.a>
              </chakra.h4>
            )}

            {isLvl1Active && (
              <chakra.div paddingTop="1" paddingLeft="3" borderLeftWidth="1px">
                {lvl1.routes!.map((lvl2, index) => {
                  if (!lvl2.routes) {
                    return (
                      <SidebarLink mt="2" key={lvl2.path} href={lvl2.path!}>
                        {lvl2.title}
                      </SidebarLink>
                    );
                  }

                  const sortedRoutes = lvl2.sort
                    ? sortBy(lvl2.routes, (i) => i.title)
                    : lvl2.routes;

                  return (
                    <SidebarCategory
                      key={lvl2.path! + index}
                      href={lvl2.path!}
                      contentRef={contentRef}
                      title={lvl2.title}
                    >
                      {sortedRoutes.map((lvl3) => (
                        <SidebarLink key={lvl3.path} href={lvl3.path!}>
                          {convertBackticksToInlineCode(lvl3.title)}
                        </SidebarLink>
                      ))}
                    </SidebarCategory>
                  );
                })}
              </chakra.div>
            )}
          </chakra.div>
        );
      })}
    </>
  );
}

export type SidebarProps = {
  routes: RouteItem[];
};

export const Sidebar = ({ routes }: SidebarProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={ref}
      aria-label="Main Navigation"
      as="nav"
      pos="sticky"
      overscrollBehavior="contain"
      top="4.5rem"
      w="20rem"
      height="calc(100vh - 4.5rem)"
      pl="6"
      pr="8"
      pt="6"
      pb="6"
      overflowY="auto"
      flexShrink={0}
      display={{ base: "none", md: "block" }}
      borderRightWidth="1px"
    >
      <SidebarContent routes={routes} contentRef={ref} />
    </Box>
  );
};
