import { BoxProps, chakra, Stack } from "@chakra-ui/react";
import { ReactNode, RefObject, useRef } from "react";

import { SidebarLink } from "~/components/sidebar/sidebar-link";

interface SidebarCategoryProps extends BoxProps {
  isMobile?: boolean;
  title: string;
  children: ReactNode;
  contentRef?: RefObject<any>;
  href: string;
}

function SidebarCategory(props: SidebarCategoryProps) {
  const { isMobile, title, children, contentRef, href, ...rest } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <chakra.div ref={ref} marginTop="2" {...rest}>
      <SidebarLink href={href}>{title}</SidebarLink>

      <Stack
        role="group"
        marginTop="2"
        marginLeft="2"
        paddingLeft="3"
        borderLeftWidth="1px"
      >
        {children}
      </Stack>
    </chakra.div>
  );
}

export default SidebarCategory;
